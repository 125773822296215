import { SetStateAction } from "react"
import styled from "styled-components"

import { IntegrationEntitySelect } from "src/components/Homes/HomeSetupWizard/IntegrationEntitySelect"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { usePostIntegrationLinkHomeInitiatedEvent } from "src/data/analytics/queries/integrationAnalyticsQueries"
import {
  TIntegration,
  TIntegrationEntity,
} from "src/data/integrations/types/integrationTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { minimumGray } from "src/ui/colors"
import ChainIcon from "src/ui/icons/chain.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { BodyMixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IntegrationHomeLinkStep({
  integration,
  homeName,
  selectedIntegrationEntity,
  setSelectedIntegrationEntity,
  error,
}: {
  integration: TIntegration
  homeName: string
  selectedIntegrationEntity: TIntegrationEntity | null
  setSelectedIntegrationEntity: (
    value: SetStateAction<TIntegrationEntity | null>
  ) => void
  error: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { mutate: mutatePostLinkHomeInitiated } =
    usePostIntegrationLinkHomeInitiatedEvent()

  useEffectOnce(() => {
    mutatePostLinkHomeInitiated({
      context: "create_home",
      integration_name: integration.name,
    })
  })

  const label = homeName
    ? `${homeName} ${t(langKeys.home).toLowerCase()}`
    : undefined

  return (
    <div>
      <StyledMTextTitle variant="heading2" marginBottom={spacing.M}>
        {t(langKeys.integrations_link_integration_for_home, { name: homeName })}
      </StyledMTextTitle>

      <IntegrationsCardHeader integration={integration} />

      <SelectBox>
        <IntegrationEntitySelect
          value={selectedIntegrationEntity}
          onSelect={setSelectedIntegrationEntity}
          integrationId={integration.integration_identifier}
          label={label}
          integrationName={integration.name}
        />
      </SelectBox>

      {error && (
        <StyledMAlert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </StyledMAlert>
      )}
    </div>
  )
}

function IntegrationsCardHeader({
  integration,
}: {
  integration: TIntegration
}) {
  return (
    <Box>
      {integration.icon ? (
        <IntegrationIcon icon={integration.icon} size={90} />
      ) : (
        <ChainIcon width={90} height={90} />
      )}

      <Details>
        <MText variant="subtitle">{integration.name}</MText>

        {<GrayText>{integration.summary}</GrayText>}
      </Details>
    </Box>
  )
}

const StyledMTextTitle = styled(MText)`
  margin-bottom: ${spacing.M};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.L};
`

const Details = styled.div`
  width: 100%;
`

const GrayText = styled.div`
  ${BodyMixin};
  color: ${minimumGray};
`

const SelectBox = styled.div`
  margin-top: ${spacing.L};
`

const StyledMAlert = styled(MBanner)`
  margin-top: ${spacing.M};
`
