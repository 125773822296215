import { useState } from "react"
import { Redirect } from "react-router-dom"
import styled from "styled-components"

import { IntegrationHomeLinkStep } from "src/components/Homes/HomeSetupWizard/IntegrationHomeLinkStep"
import {
  usePostIntegrationLinkHomeConfirmedEvent,
  usePostIntegrationLinkHomeSkippedEvent,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { usePostIntegrationLink } from "src/data/integrations/queries/integrationLinkQueries"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import {
  OwnerType,
  TIntegration,
  TIntegrationEntity,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { spacing } from "src/ui/spacing"
import { InlineWizard } from "src/ui/Wizard/InlineWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export function HomeSetupWizard({ homeId }: { homeId: string }) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const { navigate } = useRouter()

  const [currentStep, setCurrentStep] = useState(0)
  const [selectedIntegrationEntity, setSelectedIntegrationEntity] =
    useState<TIntegrationEntity | null>(null)

  const postIntegrationLink = usePostIntegrationLink()

  const fetchHome = useFetchHome({ orgId, homeId })

  const homeName = fetchHome.data?.name || ""

  const fetchIntegrations = useFetchIntegrations({
    orgId,
    filters: { configured: true },
  })

  const configuredIntegrations = fetchIntegrations.data?.integrations || []

  function handleLinkHome({ integration }: { integration: TIntegration }) {
    if (selectedIntegrationEntity?.entity_id) {
      postIntegrationLink.mutate(
        {
          integration: integration.integration_identifier,
          orgId,
          ownerType: OwnerType.ORGANIZATION,
          homeId: homeId,
          entityId: selectedIntegrationEntity?.entity_id,
          accountId: selectedIntegrationEntity?.account_id,
        },
        {
          onSuccess: () => {
            setSelectedIntegrationEntity(null)
            handleGoNextLink(integration)
          },
        }
      )
      postIntegrationLinkHomeConfirmedEvent.mutate({
        context: "create_home",
        integration_name: integration.name,
      })
    }
  }

  const postIntegrationLinkHomeSkippedEvent =
    usePostIntegrationLinkHomeSkippedEvent()

  const postIntegrationLinkHomeConfirmedEvent =
    usePostIntegrationLinkHomeConfirmedEvent()

  function handleSkip(integration: TIntegration) {
    postIntegrationLinkHomeSkippedEvent.mutate({
      integration_name: integration.name,
    })
    handleGoNextLink(integration)
  }

  function handleGoNextLink(integration: TIntegration) {
    const isLastIntegrationStep =
      integration.integration_identifier ===
      configuredIntegrations.at(-1)?.integration_identifier

    return isLastIntegrationStep
      ? navigate(Routes.Home.location(homeId))
      : setCurrentStep((currStep) => currStep + 1)
  }

  const steps: IWizardStep[] = configuredIntegrations.map((integration) => ({
    component: (
      <IntegrationHomeLinkStep
        integration={integration}
        homeName={homeName}
        selectedIntegrationEntity={selectedIntegrationEntity}
        setSelectedIntegrationEntity={setSelectedIntegrationEntity}
        error={postIntegrationLink.isError || fetchHome.isError}
      />
    ),
    backButtonLabel: t(langKeys.skip_for_now),
    onBack: () => handleSkip(integration),
    nextButtonLabel: t(langKeys.integrations_link_home),
    onNext: () => handleLinkHome({ integration }),
    nextButtonProps: {
      loading: postIntegrationLink.isLoading,
      disabled: !selectedIntegrationEntity || fetchHome.isError,
    },
  }))

  if (fetchHome.isLoading || fetchIntegrations.isLoading) {
    return <LoadingScreen debugInfo={"Loading home setup wizard"} />
  }

  if (!homeId || !homeName) {
    return <Redirect to={Routes.Dashboard.location()} />
  }

  if (configuredIntegrations.length <= 0) {
    return <Redirect to={Routes.Home.location(homeId)} />
  }

  return (
    <Box>
      <InlineWizard
        steps={steps}
        currentStep={currentStep}
        onNext={() => setCurrentStep((currStep) => currStep + 1)}
        onBack={() => setCurrentStep((currStep) => currStep - 1)}
        showProgress={false}
      />
    </Box>
  )
}

const Box = styled.div`
  max-width: 67ch;
  margin: 9vh auto;
  padding: 0 ${spacing.M};
`
